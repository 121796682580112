import get from 'lodash/get';
import { IS_DEV } from './process';

/** 
 * ISB Client Copy Utility Component 
 * @module Copy 
 * @exports module:Copy */
export default function Copy(mode, language, copy) {
  if (IS_DEV) {
    return get(copy, `${mode || "ISB"}.${language || 'EN'}`, {});
  }
  return get(copy, `${language || 'EN'}`, {});
}
