import React from "react";
import styles from "./HeaderSkeleton.module.css";

/** 
 * ISB Client HeaderSkeleton Component 
 * @module HeaderSkeleton 
 * @exports module:HeaderSkeleton */
export default function HeaderSkeleton() {
  return <div className={styles.header}></div>;
}
